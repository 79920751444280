import React from "react"
import { graphql } from "gatsby"
import {Layout} from "@components/Layout"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import theme from "@styles/theme"
import { Paragraph1, H2 } from "@styles/Global.styles"
import { Grid } from "semantic-ui-react"

interface Props{
  data: any;
  location: any;
}

class PaymentSuccess extends React.Component<Props>{

  render(){ 
    const {elements} = this.props.data.kontentItemPaymentSuccessPage;

    return (
          <Layout version="simple">
            <GenericBannerContainer 
              padding={{
                mobile: {
                    top: 20,
                    bottom: 20
                },
                desktop: {
                    top: 40,
                    bottom: 40
                }
              }}
              backgroundColor={theme.brand.colors.beige}>
              <Grid>
                  <Grid.Row>
                      <Grid.Column width={16}>
                          <H2>{elements.heading.value}</H2>
                          <Paragraph1 dangerouslySetInnerHTML={{__html: elements.body.value}} />
                      </Grid.Column>
                  </Grid.Row>
              </Grid>
            </GenericBannerContainer>
          </Layout>
      );
  }
}

export const query = graphql`{
      kontentItemPaymentSuccessPage {
        elements {
          body {
            value
          }
          heading {
            value
          }
        }
      }
  }  
`

export default PaymentSuccess;